import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Login from './Login';
import Registro from './registro';
import '../estilos/auth-styles.css';

const AuthContainer = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsFlipped((prev) => !prev);
  };

  const handleReturn = () => {
    navigate('/inicio');
  };

  return (
    <div className="auth-container">
      <div className="flip-container">
        <div className={`flipper ${isFlipped ? 'flip' : ''}`}>
          {/* Vista de Login */}
          <div className="login-side">
            <Login onToggle={handleToggle} />
            <button onClick={handleReturn} className="auth-button">
              Regresar
            </button>
          </div>

          {/* Vista de Registro */}
          <div className="register-side">
            <Registro onToggle={handleToggle} />
            <button onClick={handleReturn} className="auth-button">
              Regresar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
