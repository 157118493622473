import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import './Calendario.css';

moment.locale('es');

const Aceptadas = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [id, setId] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const usr = localStorage.getItem('usuario');

  const actualizarCita = async (cita) => {
    const citaActualizada = {
      idCita: cita.idCita,
      fechaHoraCita: moment(cita.fechaHoraCita).toISOString(),
      tipoCita: cita.tipoCita === "Presencial" ? 1 : 0,
      lugarCita: cita.lugarCita,
      ubicacionCita: cita.ubicacionCita,
      especialidadCita: cita.especialidadCita,
      notaCita: cita.notaCita || "",
      costoCita: cita.costoCita,
      statusCita: 3,
      idDoc: cita.idDoc,
      idPac: cita.idPac,
      idHos: cita.idHos,
      citaPagada: cita.citaPagada,
    };
  
    console.log('Cita actualizada:', citaActualizada);
  
    try {
      const response = await fetch(
        'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/actualizarCita',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(citaActualizada),
        }
      );
  
      if (response.ok) {
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.extendedProps.idCita !== cita.idCita)
        );
        console.log('Cita actualizada correctamente');
        setShowModal(false);
      } else {
        const errorData = await response.json();
        throw new Error(`Error al actualizar la cita: ${errorData.message || 'Bad Request'}`);
      }
    } catch (error) {
      setError(`Hubo un error al actualizar la cita: ${error.message}`);
    }
  };

  useEffect(() => {
    const obtenerId = async () => {
      try {
        const response = await fetch(
          `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/List/${usr}`,
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setId(data[0].idDoc);
        } else {
          throw new Error('Error al obtener el ID');
        }
      } catch (error) {
        setError("Error al obtener el ID");
      }
    };

    obtenerId();
  }, [usr]);

  useEffect(() => {
    const obtenerCitas = async () => {
      setLoading(true);
      if (id) {
        try {
          const response = await fetch(
            `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/List/citas_doctor/${id}`,
            { method: 'GET' }
          );

          if (response.ok) {
            const data = await response.json();
            const citasFiltradas = data.filter(cita => cita.statusCita === 2);

            const eventsFormatted = citasFiltradas.map(cita => {
              const fechaHora = moment(cita.fechaHoraCita);
              return {
                start: fechaHora.toISOString(),
                end: fechaHora.clone().add(1, 'hours').toISOString(),
                extendedProps: {
                  idCita: cita.idCita,
                  costoCita: cita.costoCita,
                  notaCita: cita.notaCita,
                  tipoCita: cita.tipoCita === 1 ? "Presencial" : "En línea",
                  especialidadCita: cita.especialidadCita,
                  fechaHoraCita: cita.fechaHoraCita,
                  lugarCita: cita.lugarCita || "Lugar de la cita",
                  ubicacionCita: cita.ubicacionCita || "Ubicación de la cita",
                  idDoc: cita.idDoc,
                  idPac: cita.idPac,
                  idHos: cita.idHos,
                  citaPagada: cita.citaPagada !== undefined ? cita.citaPagada : true,
                },
                editable: false,
                classNames: cita.tipoCita === 1 ? 'fc-event-presencial' : 'fc-event-enlinea',
              };
            });
                           
            setEvents(eventsFormatted);
          } else {
            throw new Error('Error al obtener las citas');
          }
        } catch (error) {
          setError("Hubo un error al obtener las citas");
        } finally {
          setLoading(false);
        }
      }
    };

    obtenerCitas();
  }, [id]);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event.extendedProps);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  if (loading) {
    return <div>Cargando citas...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div id="calendar" style={styles.calendarContainer}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locale="ES"
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        weekends={true}
        events={events}
        editable={false}
        selectable={true}
        eventClick={handleEventClick}
      />

      {showModal && selectedEvent && (
        <Modal
          event={selectedEvent}
          onClose={closeModal}
          onFinalize={() => actualizarCita(selectedEvent)}
        />
      )}
    </div>
  );
};

const Modal = ({ event, onClose, onFinalize }) => (
  <div style={styles.modalOverlay}>
    <div style={styles.modalContent}>
      <h2>Detalles de la Cita</h2>
      <p><strong>Tipo de Cita:</strong> {event.tipoCita}</p>
      <p><strong>Especialidad:</strong> {event.especialidadCita}</p>
      <p><strong>Fecha y Hora:</strong> {moment(event.fechaHoraCita).format('LLLL')}</p>
      <p><strong>Costo:</strong> {event.costoCita}</p>
      {event.notaCita && <p><strong>Nota:</strong> {event.notaCita}</p>}
      <button onClick={onFinalize} style={styles.finalizeButton}>Finalizar Cita</button>
      <button onClick={onClose} style={styles.closeButton}>Cerrar</button>
    </div>
  </div>
);

const styles = {
  calendarContainer: {
    width: '100%',
    margin: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'start',
  },
  closeButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  finalizeButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#FF5722',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default Aceptadas;