import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, allowedRoles }) => {
  const authToken = localStorage.getItem('authToken');
  const userRole = localStorage.getItem('capa');

  if (!authToken) {
    return <Navigate to="/Login" />;
  }

  if (!allowedRoles.includes(userRole)) {
    if (userRole === '2') return <Navigate to="/Home" />;
    if (userRole === '3') return <Navigate to="/Operador" />;
    return <Navigate to="/" />; 
  }

  return children;
};

export default PrivateRoute;
