import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Inicio from './componentes/Inicio';
import Servicios from './componentes/Servicios';
import Contacto from './componentes/Contacto';
import Login from './componentes/Login';
import Registro from './componentes/registro';
import Acercade from './componentes/Acercade';
import Recetas from './doctor/Recetas';
import Aceptadas from './doctor/Aceptadas';
import Finalizadas from './doctor/Finalizadas';
import Disponibles from './doctor/Disponibles';
import DatosDoc from './doctor/DatosDoc';
import Mycitas from './doctor/Mycitas';
import Operador from './admin/Operador';
import Usuarios from './admin/Usuarios';
import Pacientes from './admin/Pacientes';
import MenuDoctor from './admin/MenuDoctor';
import Hospitales from './admin/Hospitales';
import DetalleHospital from './admin/DetalleHospital';
import Noticias from './admin/Noticias';
import PrivateRoute from './PrivateRoute';
import Home from './doctor/Home';
import Reportes from './admin/Reportes';
import NoticiasDoc from './doctor/NoticiasDoc';
import ANoticiasDoc from './admin/NoticiasDoc';
import RegistroHospital from './RegistroHospital';
import Calificaciones from './admin/Calificaciones';
import Pagos from './admin/Pagos';
import AuthContainer from './componentes/AuthContainer';
import Perfil from './doctor/Perfil';

const AppRouter = () => {
  const isAuthenticated = !!localStorage.getItem('authToken');
  const userRole = localStorage.getItem('capa');

  // Redirigir al módulo correspondiente según la capa
  const redirectBasedOnRole = () => {
    if (userRole === '2') return <Navigate to="/Home" />;
    if (userRole === '3') return <Navigate to="/Operador" />;
    return <Navigate to="/" />;
  };

  return (
    <Router>
      <div>
        <main>
          <Routes>
            {/* Rutas públicas */}
            <Route 
              path="/Login" 
              element={isAuthenticated ? redirectBasedOnRole() : <Login />} 
            />
            <Route 
              path="/Registro" 
              element={isAuthenticated ? redirectBasedOnRole() : <Registro />} 
            />
            <Route 
              path="/AuthContainer" 
              element={isAuthenticated ? redirectBasedOnRole() : <AuthContainer />} 
            />

            {/* Rutas comunes */}
            <Route path="*" element={<Inicio />} />
            <Route path="/" element={<Inicio />} />
            <Route path="/Acerca-de" element={<Acercade />} />
            <Route path="/Servicios" element={<Servicios />} />
            <Route path="/Contacto" element={<Contacto />} />
            <Route path="/RegistroHospital" element={<RegistroHospital />} />

            {/* Rutas protegidas para usuarios con capa 2 (doctor) */}
            <Route 
              path="/Home" 
              element={
                <PrivateRoute allowedRoles={['2']}>
                  <Home />
                </PrivateRoute>
              }
            >
              <Route path="finalizadas" element={<Finalizadas />} />
              <Route path="disponibles" element={<Disponibles />} />
              <Route path="aceptadas" element={<Aceptadas />} />
              <Route path="noticias-doc" element={<NoticiasDoc />} />
            </Route>

            <Route 
              path="/Disponibles" 
              element={
                <PrivateRoute allowedRoles={['2']}>
                  <Disponibles />
                </PrivateRoute>
              }
            />
            <Route 
              path="/Perfil" 
              element={
                <PrivateRoute allowedRoles={['2']}>
                  <Perfil />
                </PrivateRoute>
              }
            />
            <Route 
              path="/DatosDoc" 
              element={
                <PrivateRoute allowedRoles={['2']}>
                  <DatosDoc />
                </PrivateRoute>
              }
            />
            <Route 
              path="/Mycitas" 
              element={
                <PrivateRoute allowedRoles={['2']}>
                  <Mycitas />
                </PrivateRoute>
              }
            />
            <Route 
              path="/Recetas" 
              element={
                <PrivateRoute allowedRoles={['2']}>
                  <Recetas />
                </PrivateRoute>
              }
            />

            {/* Rutas protegidas para usuarios con capa 3 (administrador) */}
            <Route 
              path="/Operador" 
              element={
                <PrivateRoute allowedRoles={['3']}>
                  <Operador />
                </PrivateRoute>
              }
            >
              <Route path="hospitales" element={<Hospitales />} />
              <Route path="pacientes" element={<Pacientes />} />
              <Route path="usuarios" element={<Usuarios />} />
              <Route path="menu-doctor" element={<MenuDoctor />} />
              <Route path="detalleHospital" element={<DetalleHospital />} />
              <Route path="noticias" element={<Noticias />} />
              <Route path="reportes" element={<Reportes />} />
              <Route path="a-noticias-doc" element={<ANoticiasDoc />} />
              <Route path="calificaciones" element={<Calificaciones />} />
              <Route path="pagos" element={<Pagos />} />
            </Route>
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default AppRouter;
