import React, { useState, useEffect } from "react";
import '../App.css';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DatosDoc = () => {
    const [formData, setFormData] = useState({
        cedDoc: "",
        genDoc: "",
        cuentaDoc: "",
        bancoDoc: "",
        rfcDoc: "",
        fechaNacimientoDoc: "",
        especialidadDoc: "",
        institutoDoc: ""
    });
    const [documentos, setDocumentos] = useState({
        Cedula: { file: null, completed: false },
        INE: { file: null, completed: false },
        CURP: { file: null, completed: false },
    });
    const [errors, setErrors] = useState({});
    const usuario = localStorage.getItem('usuario');
    const [especialidades, setEspecialidades] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEspecialidades = async () => {
            try {
                const response = await fetch(
                    "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Especialidades/api/List"
                );
                if (response.ok) {
                    const data = await response.json();
                    setEspecialidades(data);
                } else {
                    toast.error("Error al cargar las especialidades");
                }
            } catch (error) {
                console.error("Error fetching especialidades:", error);
                toast.error("No se pudieron cargar las especialidades");
            }
        };
        fetchEspecialidades();
    }, []);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
    
        if (id === "rfcDoc") {
            const rfc = value.toUpperCase();
            if (rfc.length > 13) {
                toast.error("El RFC no debe exceder los 13 caracteres.");
                return;
            }
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: rfc,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [id]: value,
            }));
        }
    };    

    const isAgeValid = (date) => {
        const birthDate = new Date(date);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const isOlderThan14 =
            age > 14 || (age === 14 && today >= new Date(birthDate.setFullYear(today.getFullYear())));
        return isOlderThan14;
    };
    

    const handleFileChange = (e, docType) => {
        const file = e.target.files[0];
        if (file) {
            setDocumentos((prevDocumentos) => ({
                ...prevDocumentos,
                [docType]: { file: file, completed: true }
            }));
            toast.success(`Documento ${docType} cargado con éxito`);
        }
    };

    const handleDrop = (e, docType) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            setDocumentos((prevDocumentos) => ({
                ...prevDocumentos,
                [docType]: { file: file, completed: true }
            }));
            toast.success(`Documento ${docType} cargado con éxito`);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.rfcDoc || formData.rfcDoc.length !== 13) {
            toast.error("El RFC debe tener exactamente 13 caracteres.");
            return;
        }

        if (!isAgeValid(formData.fechaNacimientoDoc.split('T')[0])) {
            toast.error("Debes ser mayor de 14 años.");
            return;
        }

        if (!documentos.Cedula.file || !documentos.INE.file || !documentos.CURP.file) {
            toast.error("Por favor sube todos los documentos requeridos.");
            setErrors({ apiError: "Faltan documentos por cargar." });
            return;
        }

        try {
            const formDataDocs = new FormData();
            formDataDocs.append('rutaCedula', documentos.Cedula.file);
            formDataDocs.append('rutaIne', documentos.INE.file);
            formDataDocs.append('rutaCurp', documentos.CURP.file);
            const validationResponse = await fetch(
                `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Uploadhospital/validacionDoctor/${usuario}`,
                {
                    method: 'POST',
                    body: formDataDocs,
                }
            );

            if (!validationResponse.ok) {
                const errorData = await validationResponse.json();
                throw new Error(errorData.Mensaje || 'Error al validar los documentos');
            }

            toast.success("Documentos validados exitosamente.");

            const altaResponse = await fetch(
                `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/AltaDoc/${usuario}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (!altaResponse.ok) {
                const errorData = await altaResponse.json();
                throw new Error(errorData.message || 'Error al registrar el doctor');
            }

            await altaResponse.json();
            setFormData({
                cedDoc: '',
                genDoc: '',
                cuentaDoc: '',
                bancoDoc: ''
            });
            setDocumentos({
                Cedula: { file: null, completed: false },
                INE: { file: null, completed: false },
                CURP: { file: null, completed: false },
            });

            setErrors({});
            toast.success("Doctor registrado exitosamente.");
            navigate('/Home');
        } catch (error) {
            console.error('Error al registrar:', error);
            setErrors({ apiError: error.message });
            toast.error(error.message);
        }
    };

    return (
        <div className="fondo">
            <div className="doctor-container-dos">
                <form onSubmit={handleSubmit}>
                    <div className="titulos">
                        <h2>Registro Doctor</h2>
                    </div>

                    <div className="resaltado-black">
                        <label htmlFor="cedDoc">Cédula:</label>
                        <input
                            type="text"
                            id="cedDoc"
                            className="global-input"
                            value={formData.cedDoc}
                            onChange={handleInputChange}
                            placeholder="Ingresa tu cédula"
                            required
                        />
                    </div>

                    <div className="resaltado-black">
                        <label htmlFor="genDoc">Género:</label>
                        <select
                            id="genDoc"
                            className="form-select"
                            value={formData.genDoc}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Selecciona un género</option>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                    </div>

                    <div className="resaltado-black">
                        <label htmlFor="cuentaDoc">Cuenta Bancaria:</label>
                        <input
                            type="text"
                            id="cuentaDoc"
                            className="global-input"
                            value={formData.cuentaDoc}
                            onChange={handleInputChange}
                            placeholder="Ingresa la cuenta donde te pagaremos"
                            required
                        />
                    </div>

                    <div className="resaltado-black">
                        <label htmlFor="bancoDoc">Banco:</label>
                        <input
                            type="text"
                            id="bancoDoc"
                            className="global-input"
                            value={formData.bancoDoc}
                            onChange={handleInputChange}
                            placeholder="Ingresa el banco al que pertenece la cuenta"
                            required
                        />
                    </div>

                    <div className="resaltado-black">
                        <label htmlFor="rfcDoc">RFC:</label>
                        <input
                            type="text"
                            id="rfcDoc"
                            className="global-input"
                            value={formData.rfcDoc}
                            onChange={handleInputChange}
                            placeholder="Ingresa tu RFC"
                            required
                        />
                    </div>

                    <div className="resaltado-black">
                        <label htmlFor="fechaNacimientoDoc">Fecha de Nacimiento:</label>
                        <input
                            type="date"
                            id="fechaNacimientoDoc"
                            className="global-input"
                            value={formData.fechaNacimientoDoc.split('T')[0] || ""}
                            onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (!isAgeValid(selectedDate)) {
                                    toast.error("Debes ser mayor de 14 años.");
                                    return;
                                }
                                setFormData({
                                    ...formData,
                                    fechaNacimientoDoc: new Date(selectedDate).toISOString(),
                                });
                            }}
                            required
                        />
                    </div>

                    <div className="resaltado-black">
                        <label htmlFor="especialidadDoc">Especialidad:</label>
                        <select
                            id="especialidadDoc"
                            className="form-select"
                            value={formData.especialidadDoc}
                            onChange={(e) => setFormData({ ...formData, especialidadDoc: e.target.value })}
                            required
                        >
                            <option value="">Selecciona una especialidad</option>
                            {especialidades.map((especialidad) => (
                                <option key={especialidad.id} value={especialidad.medikit_id}>
                                    {especialidad.nombre}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="resaltado-black">
                        <label htmlFor="institutoDoc">Instituto en el que estudió:</label>
                        <input
                            type="text"
                            id="institutoDoc"
                            className="global-input"
                            value={formData.institutoDoc}
                            onChange={handleInputChange}
                            placeholder="Ingresa tu instituto"
                            required
                        />
                    </div>

                    <div className="resaltado-black">
                        <h3 className="documentos-titulo">Documentos requeridos</h3>
                    </div>

                    {/* Espacio para los documentos */}
                    <div className="document-section">
                        {["Cedula", "INE", "CURP"].map((docType) => (
                            <div key={docType} className="document-upload">
                                <label htmlFor={`${docType}-upload`}>
                                    {docType}:
                                </label>
                                <div
                                    className="drop-area"
                                    onDrop={(e) => handleDrop(e, docType)}
                                    onDragOver={handleDragOver}
                                    onClick={() => document.getElementById(`${docType}-upload`).click()}
                                >
                                    <p>{documentos[docType].file ? documentos[docType].file.name : "Arrastra y suelta un archivo aquí o haz clic en el boton"}</p>
                                </div>
                                <input
                                    type="file"
                                    id={`${docType}-upload`}
                                    onChange={(e) => handleFileChange(e, docType)}
                                    style={{ display: "none" }}
                                />
                                <label htmlFor={`${docType}-upload`} className="file-upload-button">Seleccionar archivo</label>
                                {documentos[docType].completed && <span className="completed">Completado</span>}
                            </div>
                        ))}
                    </div>

                    {errors.apiError && <p className="error">{errors.apiError}</p>}
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <button type="submit" className="btn btn-primary" style={{ width: '30%' }}>Enviar</button>
                    </div>
                </form>
                <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
            </div>
        </div>
    );
};

export default DatosDoc;