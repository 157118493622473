import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Registro = ({ onToggle }) => {
  const [formData, setFormData] = useState({
    nombreUs: '',
    apellidoUs: '',
    telefonoUs: '',
    correoUs: '',
    contraUs: '',
    statusUs: 1,
    tipoUs: 2,
    paisUs: '',
    estadoUs: '',
    ciudadUs: '',
    direccionUs: '',
    cpUs: '',
  });

  const [errors, setErrors] = useState({});
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch(
          'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Medikit/api/Todos'
        );
        const data = await response.json();
        setLocationOptions(data);
      } catch (error) {
        console.error('Error fetching location options:', error);
      }
    };
  
    fetchLocations();
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'telefonoUs') {
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validate = () => {
    const newErrors = {};

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = 'Este campo es obligatorio.';
      }
    });

    if (!formData.telefonoUs.match(/^\d{10}$/)) {
      newErrors.telefonoUs = 'El teléfono debe tener exactamente 10 dígitos.';
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    if (!emailRegex.test(formData.correoUs)) {
      newErrors.correoUs = 'El correo debe ser válido y terminar en .com o ser de Gmail.';
    }

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+=<>?{}[\]~]).{6,}$/;
    if (!passwordRegex.test(formData.contraUs)) {
      newErrors.contraUs =
        'La contraseña debe tener al menos 6 caracteres, una letra mayúscula y un carácter especial.';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error('Por favor revisa los campos del formulario.');
    } else {
      try {
        const response = await fetch(
          'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Registro/Nuevo',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          if (errorData.message && errorData.message.includes('correo ya registrado')) {
            throw new Error('El correo ingresado ya se encuentra en uso.');
          }
          throw new Error('Error en la solicitud: ' + response.status);
        }

        const data = await response.json();
        console.log('Registro exitoso:', data);

        setFormData({
          nombreUs: '',
          apellidoUs: '',
          telefonoUs: '',
          correoUs: '',
          contraUs: '',
          statusUs: 1,
          tipoUs: 2,
          direccionUs: '',
          cpUs: '',
          paisUs: '',
          estadoUs: '',
          ciudadUs: '',
        });

        setErrors({});
        toast.success('Registro exitoso');
        onToggle();
      } catch (error) {
        console.error('Error al registrar:', error);
        setErrors({ apiError: error.message });
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="auth-form-container">
      <h2 className="auth-title">Nuevo Doctor</h2>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Nombre:</label>
          <input
            type="text"
            name="nombreUs"
            value={formData.nombreUs}
            onChange={handleChange}
            required
            className="form-input"
            placeholder="Ingresa tu nombre"
          />
          {errors.nombreUs && <span className="error-message">{errors.nombreUs}</span>}
        </div>

        <div className="form-group">
          <label>Apellido:</label>
          <input
            type="text"
            name="apellidoUs"
            value={formData.apellidoUs}
            onChange={handleChange}
            required
            className="form-input"
            placeholder="Ingresa tu apellido"
          />
          {errors.apellidoUs && <span className="error-message">{errors.apellidoUs}</span>}
        </div>

        <div className="form-group">
          <label>Teléfono:</label>
          <input
            type="tel"
            name="telefonoUs"
            value={formData.telefonoUs}
            onChange={handleChange}
            required
            className="form-input"
            placeholder="Ingresa tu teléfono"
          />
          {errors.telefonoUs && <span className="error-message">{errors.telefonoUs}</span>}
        </div>

        <div className="form-group">
          <label>Correo:</label>
          <input
            type="email"
            name="correoUs"
            value={formData.correoUs}
            onChange={handleChange}
            required
            className="form-input"
            placeholder="Ingresa tu correo electrónico"
          />
          {errors.correoUs && <span className="error-message">{errors.correoUs}</span>}
        </div>

        <div className="form-group">
          <label>Contraseña:</label>
          <input
            type="password"
            name="contraUs"
            value={formData.contraUs}
            onChange={handleChange}
            required
            className="form-input"
            placeholder="Ingresa tu contraseña"
          />
          {errors.contraUs && <span className="error-message">{errors.contraUs}</span>}
        </div>

        <div className="form-group">
          <label>Estado:</label>
          <select
            name="estadoSelector"
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#fff',
              color: '#333',
              marginBottom: '15px',
            }}
            value={formData.estadoUs}
            onChange={(e) => {
              const selectedState = locationOptions.find(
                (opt) => opt.stateCode === e.target.value
              );

              if (selectedState) {
                setFormData({
                  ...formData,
                  paisUs: selectedState.countryCode,
                  estadoUs: selectedState.stateCode,
                  ciudadUs: selectedState.stateName, // Asignar stateName como ciudad
                });
              }
            }}
            required
            className="form-input"
          >
            <option value="">Selecciona un estado</option>
            {locationOptions.map((state) => (
              <option key={state.stateCode} value={state.stateCode}>
                {state.stateName}
              </option>
            ))}
          </select>
          {errors.estadoUs && <span className="error-message">{errors.estadoUs}</span>}
        </div>


        <div className="form-group">
          <label>Dirección:</label>
          <input
            type="text"
            name="direccionUs"
            value={formData.direccionUs}
            onChange={handleChange}
            required
            className="form-input"
            placeholder="Ingresa tu direccion"
          />
          {errors.direccionUs && <span className="error-message">{errors.direccionUs}</span>}
        </div>

        <div className="form-group">
          <label>Codigo Postal:</label>
          <input
            type="number"
            name="cpUs"
            value={formData.cpUs}
            onChange={handleChange}
            required
            className="form-input"
            placeholder="Ingresa tu direccion"
          />
          {errors.cpUs && <span className="error-message">{errors.cpUs}</span>}
        </div>

        <button type="submit" className="auth-button">
          Registrar
        </button>
      </form>
      <div className="toggle-form">
        ¿Ya tienes una cuenta?{' '}
        <span onClick={onToggle} className="toggle-link">
          Inicia sesión aquí
        </span>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Registro;