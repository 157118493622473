import React, { useEffect, useState } from 'react';
import '../App.css';
import "../estilos/disponibles.css";
import HeaderDoc from './HeaderDoc';

function formFechaHora(fechaHoraString) {
  const fecha = new Date(fechaHoraString);
  const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric' };
  const opcionesHora = { hour: '2-digit', minute: '2-digit' };
  
  return {
    fecha: fecha.toLocaleDateString(undefined, opcionesFecha),
    hora: fecha.toLocaleTimeString(undefined, opcionesHora)
  };
}

function Mycitas() {
  const [id, setId] = useState(null);
  const [citas, setCitas] = useState([]);
  const usr = localStorage.getItem('usuario');

  useEffect(() => {
    const obtenerId = async () => {
      try {
        const response = await fetch(
          `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/List/${usr}`,
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setId(data[0].idDoc);
        } else {
          console.error('Error al obtener el ID:', response.statusText);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };

    obtenerId();
  }, [usr]);

  useEffect(() => {
    const obtenerCitas = async () => {
      if (id) {
        try {
          const response = await fetch(
            `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/List/citas_doctor/${id}`,
            { method: 'GET' }
          );

          if (response.ok) {
            const data = await response.json();
            const citasFiltradas = data.filter(cita => cita.statusCita === 1);
            setCitas(citasFiltradas);
          } else {
            console.error('Error al obtener las citas:', response.statusText);
          }
        } catch (error) {
          console.error('Error en la solicitud:', error);
        }
      }
    };

    obtenerCitas();
  }, [id]);

  return (
    <div className="fondo">
      <HeaderDoc/>
      <h2 className="titulos">Consultas Asignadas</h2>
      <div className="contenedor-cartas">
        {citas.length > 0 ? (
          citas.map((cita) => {
            const { fecha, hora } = formFechaHora(cita.fechaHoraCita);
            return (
              <div key={cita.idCita} className="carta-cita">
                <div className="carta-contenido">
                  <p><strong>Fecha:</strong> {fecha}</p>
                  <p><strong>Hora:</strong> {hora}</p>

                  {cita.tipoCita === 1 && <p><strong>Tipo de Cita:</strong> Presencial</p>}
                  {cita.tipoCita === 2 && <p><strong>Tipo de Cita:</strong> En línea</p>}
                  {cita.especialidadCita !== 0 && (
                    <p><strong>Especialidad:</strong> {cita.especialidadCita}</p>
                  )}

                  <p><strong>Estado:</strong> {cita.citaPagada ? 'Pagada' : 'No pagada'}</p>
                </div>
              </div>
            );
          })
        ) : (
          <p>No hay citas disponibles.</p>
        )}
      </div>
    </div>
  );
}

export default Mycitas;