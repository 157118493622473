import React, { useEffect, useState } from "react";
import '../estilos/disponibles.css';

function formFechaHora(fechaHoraString) {
  const fecha = new Date(fechaHoraString);
  const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric' };
  const opcionesHora = { hour: '2-digit', minute: '2-digit' };
  
  return {
    fecha: fecha.toLocaleDateString(undefined, opcionesFecha),
    hora: fecha.toLocaleTimeString(undefined, opcionesHora)
  };
}

const Disponibles = () => {
  const [id, setId] = useState(null);
  const [citas, setCitas] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showButtons, setShowButtons] = useState(null);
  const usr = localStorage.getItem('usuario');

  useEffect(() => {
    const obtenerId = async () => {
      try {
        const response = await fetch(
          `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/List/${usr}`,
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setId(data[0].idDoc);
        } else {
          throw new Error('Error al obtener el ID');
        }
      } catch (error) {
        setError("Error al obtener el ID");
      }
    };
    obtenerId();
  }, [usr]);

  useEffect(() => {
    const obtenerCitas = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/List/citas_global",
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          const citasFiltradas = data.filter(cita => cita.statusCita === 1);
          setCitas(citasFiltradas);
        } else {
          throw new Error('Error al obtener las citas');
        }
      } catch (error) {
        setError("Hubo un error al obtener las citas");
      } finally {
        setLoading(false);
      }
    };

    obtenerCitas();
  }, []);

  useEffect(() => {
    const obtenerEspecialidades = async () => {
      try {
        const response = await fetch(
          "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Especialidades/api/List",
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setEspecialidades(data);
        } else {
          throw new Error('Error al obtener las especialidades');
        }
      } catch (error) {
        setError("Hubo un error al obtener las especialidades");
      }
    };

    obtenerEspecialidades();
  }, []);

  const obtenerNombreEspecialidad = (idEspecialidad) => {
    const especialidad = especialidades.find(e => e.id === idEspecialidad);
    return especialidad ? especialidad.nombre : 'Sin especialidad';
  };

  const actualizarCita = async (cita) => {
    const citaActualizada = { 
      ...cita, 
      statusCita: 2, 
      idDoc: id,
      ubicacionCita: ""
    };
console.log (citaActualizada)
    try {
      const response = await fetch(
        "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/actualizarCita",
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(citaActualizada),
        }
      );

      if (response.ok) {
        const nuevasCitas = citas.filter(c => c.idCita !== cita.idCita);
        setCitas(nuevasCitas);
      } else {
        throw new Error('Error al actualizar la cita');
      }
    } catch (error) {
      setError("Hubo un error al actualizar la cita");
    }
  };

  if (error) {
    return <div>{error}</div>;
  }
  if (loading) {
    return <div className="cargando">Cargando citas...</div>;
  }

  return (
    <div>
      <h1 className='doctor-title'>Citas Disponibles</h1>
      <div className="project-boxes jsGridView">
        {citas.map((cita, index) => {
          const { fecha, hora } = formFechaHora(cita.fechaHoraCita);
          return (
            <div key={cita.idCita} className="project-box-wrapper">
              <div
                className={`project-box color-${(index % 6) + 1}`}
                onClick={() => setShowButtons(showButtons === cita.idCita ? null : cita.idCita)}
              >
                <div className="project-box-header">
                  <span>{fecha} - {hora}</span>
                </div>
                <div className="project-box-content-header">
                  <p className="box-content-header">{cita.tipoCita === 1 ? "Presencial" : "En línea"}</p>
                  <p className="box-content-subheader">{obtenerNombreEspecialidad(cita.especialidadCita)}</p>
                </div>
                <div className="box-progress-wrapper">
                  <p>Costo</p>
                  <p>{cita.costoCita.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</p>
                </div>
                {cita.notaCita && (
                  <div className="box-progress-wrapper">
                    <p>Nota de la Cita</p>
                    <p>{cita.notaCita}</p>
                  </div>
                )}
                {showButtons === cita.idCita && (
                  <div className="project-btn-wrapper">
                    <button onClick={() => actualizarCita(cita)} className="btn-finalizar">Aceptar</button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Disponibles;