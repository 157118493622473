import React, { useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../estilos/receta.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../estilos/docstyles.css'

function Recetas() {
  const location = useLocation();
  const navigate = useNavigate();
  const { Cita } = location.state || {};
  const [setCitas] = useState([]);
  const [medicamentos, setMedicamentos] = useState([]);
  const [diagnosticoRec, setDiagnosticoRec] = useState("");
  const [notaRec, setNotaRec] = useState("");
  const [loading, setLoading] = useState(false);
  const [ModalAlta, setModalAlta] = useState(false);
  const [newMedicamento, setNewMedicamento] = useState({ 
    nombre: "", 
    intervalo: "", 
    duracion: "", 
    ean: "" 
  });
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const abrirModalAlta = useCallback(() => {
    setModalAlta(true);
    setNewMedicamento({ 
      nombre: "", 
      intervalo: "", 
      duracion: "", 
      ean: "" 
    });
    setSearchResults([]);
    setSearchInput("");
  }, []);

  const cerrarModalAlta = useCallback(() => {
    setSearchResults([]);
    setModalAlta(false);
    setSearchInput("");
    setNewMedicamento({ 
      nombre: "", 
      intervalo: "", 
      duracion: "", 
      ean: "" 
    });
  }, []);

  const searchMedicamentos = useCallback(async (termino) => {
    if (!termino) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(
        `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Medikit/api/Medicamentos/${termino}`
      );
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data || []);
      }
    } catch (error) {
      console.error("Error buscando medicamentos:", error);
      setSearchResults([]);
    }
  }, []);

  const handleSearchChange = useCallback((value) => {
    setSearchInput(value);
    const delayDebounceFn = setTimeout(() => {
      searchMedicamentos(value);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchMedicamentos]);

  const selectMedicamento = useCallback((medicamento) => {
    setNewMedicamento(prev => ({
      ...prev,
      nombre: medicamento.descriptionGenericName,
      ean: medicamento.ean
    }));
    setSearchResults([]);
    setSearchInput(medicamento.descriptionGenericName);
  }, []);

  const agregarMedicamento = useCallback(() => {
    if (!newMedicamento.nombre || !newMedicamento.intervalo || !newMedicamento.duracion) {
      toast.error("Por favor, complete todos los campos del medicamento.");
      return;
    }
    
    setMedicamentos(prev => [...prev, { ...newMedicamento }]);
    cerrarModalAlta();
    toast.success("Medicamento agregado");
  }, [newMedicamento, cerrarModalAlta]);

  const eliminarMedicamento = useCallback((index) => {
    setMedicamentos(prev => prev.filter((_, i) => i !== index));
    toast.success("Medicamento eliminado");
  }, []);

  const handleNewMedicamentoChange = useCallback((field, value) => {
    setNewMedicamento(prev => ({ ...prev, [field]: value }));
  }, []);

  const validarMedicamentos = useCallback(() => {
    return medicamentos.length > 0 && medicamentos.every(medicamento =>
      medicamento.nombre && medicamento.intervalo && medicamento.duracion
    );
  }, [medicamentos]);

  const enviarRecetaMedikit = async () => {
    if (!validarMedicamentos() || !diagnosticoRec.trim()) {
      toast.error("Por favor complete todos los campos requeridos.");
      return false;
    }
  
    const medikitData = {
      Payer: "0018c000029jpBgAAI",
      Organization: "0016T000035EfEVQA0",
      PurchaserPlan: "0SbE200000008DhKAI",
      HealthcareProvider: "0cmE20000001JWrIAM",
      Patient: "001E200000a7q0PIAQ",
      SourceSystem: "0016T000035EfEVQA0",
      SourceSystemIdentifier: "121441232",
      Autorization: "",
      Category: "Consulta Medica",
      StartDate: "2024-03-10 01:00:00",
      EndDate: "2024-03-10 01:00:01",
      Medication: medicamentos.map(med => ({
        MedicationCode: "7501299308684",
        Substitute: "false",
        InitialFillQuantity: 2,
        PatientInstructions: `wawawaw`,
        InitialFillDuration: "10",
        MedicationAdministrationContext: "0iP6T000000000CUAQ",
        Diagnosis: "J00X",
        SourceSystemIdentifier: "1214412321",
        Dosage: 1,
        Frequency: 1
      }))
    };
  
    setLoading(true);
    try {
      const response = await fetch(
        'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Receta/sendPrescription',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(medikitData),
        }
      );
  
      if (!response.ok) {
        throw new Error(await response.text());
      }
      
      toast.success("Receta Medikit enviada con éxito");
      return true;
    } catch (error) {
      console.error(error);
      toast.error("Error al enviar la receta a Medikit");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const enviarReceta = async () => {
    const recetaData = {
      medicamentosRec: medicamentos.map(m => m.nombre).join(", "),
      intervalosRec: medicamentos.map(m => m.intervalo).join(", "),
      duracionRec: medicamentos.map(m => m.duracion).join(", "),
      easRec: medicamentos.map(m => m.ean).join(", "),
      diagnosticoRec: diagnosticoRec,
      notaRec: notaRec,
      idCita: Cita.idCita
    };

    try {
      const response = await fetch(
        'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Receta/api/Alta/Medikit',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(recetaData),
        }
      );

      if (!response.ok) throw new Error('Error en la respuesta del servidor');
      
      const result = await response.json();
      toast.success(result.message || "Receta enviada con éxito");
      return true;
    } catch (error) {
      console.error(error);
      toast.error("Error al enviar la receta");
      return false;
    }
  };

  const actualizarCita = async (cita) => {
    try {
      const citaActualizada = {
        ...cita,
        statusCita: 4,
        fechaHoraCita: cita.fechaHoraCita
      };

      const response = await fetch(
        'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/actualizarCita',
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(citaActualizada),
        }
      );

      if (!response.ok) throw new Error('Error en la respuesta del servidor');

      setCitas(prev => prev.filter(c => c.idCita !== cita.idCita));
      toast.success('Cita actualizada correctamente');
      return true;
    } catch (error) {
      console.error(error);
      toast.error('Error al actualizar la cita');
      return false;
    }
  };

  const handleEnviarRecetaYActualizarCita = async () => {
    if (!validarMedicamentos()) {
      toast.error("Por favor agregue al menos un medicamento y complete todos sus campos.");
      return;
    }

    if (!diagnosticoRec.trim()) {
      toast.error("Por favor ingrese un diagnóstico.");
      return;
    }

    setLoading(true);
    try {
      // Primero enviar a Medikit
      const medikitEnviado = await enviarRecetaMedikit();
      
      if (medikitEnviado) {
        // Luego guardar en la base de datos local
        const recetaEnviada = await enviarReceta();
        
        if (recetaEnviada) {
          const citaActualizada = await actualizarCita(Cita);
          if (citaActualizada) {
            navigate("/Home/Finalizadas");
          }
        }
      }
    } catch (error) {
      toast.error("Error al procesar la receta.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="doctor-fondo" style={{ display: 'flex' }}>
      <div className="contenido-principal" style={{ flex: '1', paddingRight: '250px' }}>
        <h2 className="doctor-title">Generar Receta</h2>
        <div className="form-container">
          <div className="diagnostico-container">
            <label className="doctor-subtitle">Diagnóstico</label>
            <input
              className="large-input"
              placeholder="Ingrese el diagnóstico"
              value={diagnosticoRec}
              maxLength="400"
              onChange={(e) => setDiagnosticoRec(e.target.value)}
            />
          </div>
          
          <div className="nota-container">
            <label className="doctor-subtitle">Nota</label>
            <input
              className="large-input"
              placeholder="Ingrese una nota adicional (opcional)"
              value={notaRec}
              maxLength="400"
              onChange={(e) => setNotaRec(e.target.value)}
            />
          </div>

          <div className="medicamentos-container">
            {medicamentos.length === 0 ? (
              <div className="no-medicamentos">No hay medicamentos</div>
            ) : (
              <div className="medicamentos-lista">
                {medicamentos.map((medicamento, index) => (
                  <div key={`med-${index}`} className="medicamento-item">
                    <div className="medicamento-detalles">
                      <span className="medicamento-nombre">{medicamento.nombre}</span>
                      <span className="medicamento-intervalo">Cada {medicamento.intervalo} horas</span>
                      <span className="medicamento-duracion">Durante {medicamento.duracion} días</span>
                    </div>
                    <button 
                      onClick={() => eliminarMedicamento(index)} 
                      className="btn-eliminar"
                      type="button"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="sidebar-buttons">
        <div>
          <button 
            className="sidebar-button button-add"
            onClick={abrirModalAlta}
            type="button"
          >
            Agregar Medicamento
          </button>
          <button 
            className="sidebar-button button-send"
            onClick={handleEnviarRecetaYActualizarCita} 
            disabled={loading}
            type="button"
          >
            {loading ? "Enviando..." : "Enviar Receta"}
          </button>
          <button 
            onClick={() => navigate('/home/finalizadas')} 
            className="sidebar-button button-cancel"
            type="button"
          >
            Cancelar Receta
          </button>
        </div>
      </div>

      {ModalAlta && (
        <div className="modal">
          <div className="modal-content">
            <h2>Agregar Medicamento</h2>
            <div className="form-group">
              <label>Nombre del Medicamento:</label>
              <input
                type="text"
                value={searchInput}
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Escriba para buscar medicamentos..."
                className="search-input"
                autoComplete="off"
              />
              
              {searchResults.length > 0 && (
                <div className="suggestions-container">
                  {searchResults.map((med) => (
                    <div 
                      key={`sugg-${med.ean}`}
                      className="suggestion-item"
                      onClick={() => selectMedicamento(med)}
                    >
                      <div className="suggestion-name">{med.descriptionGenericName}</div>
                      <div className="suggestion-brand">{med.brand}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="form-group">
              <label>Intervalo (horas):</label>
              <input
                type="number"
                min="1"
                max="24"
                value={newMedicamento.intervalo}
                onChange={(e) => handleNewMedicamentoChange("intervalo", e.target.value)}
                className="number-input"
              />
            </div>

            <div className="form-group">
              <label>Duración (días):</label>
              <input
                type="number"
                min="1"
                max="365"
                value={newMedicamento.duracion}
                onChange={(e) => handleNewMedicamentoChange("duracion", e.target.value)}
                className="number-input"
              />
            </div>

            <div className="modal-buttons">
              <button 
                onClick={cerrarModalAlta} 
                className="button-cancel"
                type="button"
              >
                Cancelar
              </button>
              <button 
                onClick={agregarMedicamento} 
                className="button-add"
                type="button"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default Recetas;