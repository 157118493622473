import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  Person as PersonIcon,
  Logout as LogoutIcon,
  MedicalServices as MedicalServicesIcon,
  LocalHospital as LocalHospitalIcon,
  Group as GroupIcon,
  Newspaper as NewspaperIcon,
  Report as ReportIcon,
} from '@mui/icons-material';
import { FaStar, FaMoneyBill } from 'react-icons/fa6';
import './adminstyles.css';

const Operador = () => {
  const location = useLocation();
  const isRootPath = location.pathname === '/Operador';

  const handleLogout = () => {
    localStorage.clear();
  };

  return (
    <div className="container">

<div className="sidebar">
  <div className="sidebar-header">
    <img src="/headerLogo.png" alt="Logo Empresa" className="logo" />
    <h2>Panel de Administración</h2>
  </div>
  <div className="sidebar-menu">
      <Link to="usuarios" className="link">
        <PersonIcon className="link-icon" />
        Usuarios
      </Link>
      <Link to="menu-doctor" className="link">
        <MedicalServicesIcon className="link-icon" />
        Doctores
      </Link>
      <Link to="hospitales" className="link">
        <LocalHospitalIcon className="link-icon" />
        Hospitales
      </Link>
      <Link to="pacientes" className="link">
        <GroupIcon className="link-icon" />
        Pacientes
      </Link>
      <Link to="DetalleHospital" className="link">
        <LocalHospitalIcon className="link-icon" />
        Detalle Hospital
      </Link>
      <Link to="noticias" className="link">
        <NewspaperIcon className="link-icon" />
        Noticias
      </Link>
      <Link to="reportes" className="link">
        <ReportIcon className="link-icon" />
        Reportes
      </Link>
      <Link to="calificaciones" className="link">
        <FaStar className="link-icon" />
        Calificaciones
      </Link>
      <Link to="pagos" className="link">
        <FaMoneyBill className="link-icon" />
        Pagos
      </Link>
    </div>

    <Link to="/" onClick={handleLogout} className="bye">
      <LogoutIcon className="link-icon" />
      Cerrar Sesión
    </Link>
  </div>


      {/* Main Content */}
      <div className="content">
        {isRootPath && (
          <>
            <h1 className="welcome-text">Bienvenido al módulo de administración</h1>
            <p className="instructions">Seleccione una opción del menú para continuar</p>
          </>
        )}

        <Outlet />
      </div>
    </div>
  );
};

export default Operador;
