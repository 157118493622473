import React, { useEffect, useState } from 'react';
import '../App.css';
import '../estilos/disponibles.css';
import { useNavigate } from 'react-router-dom';
import PdfDoc from './PdfDoc'; 

function formFechaHora(fechaHoraString) {
  const fecha = new Date(fechaHoraString);
  const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric' };
  const opcionesHora = { hour: '2-digit', minute: '2-digit' };
  
  return {
    fecha: fecha.toLocaleDateString(undefined, opcionesFecha),
    hora: fecha.toLocaleTimeString(undefined, opcionesHora)
  };
}

function Finalizadas() {
  const [id, setId] = useState(null);
  const [especialidades, setEspecialidades] = useState([]);
  const [citas, setCitas] = useState([]); 
  const [citaSeleccionada, setCitaSeleccionada] = useState(null);
  const usr = localStorage.getItem('usuario');
  const navigate = useNavigate(); 

  useEffect(() => {
    const obtenerId = async () => {
      try {
        const response = await fetch(
          `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/List/${usr}`,
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setId(data[0].idDoc);
        } else {
          console.error('Error al obtener el ID:', response.statusText);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };

    obtenerId();
  }, [usr]);

  useEffect(() => {
    const obtenerCitas = async () => {
      if (id) { 
        try {
          const response = await fetch(
            `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/List/citas_doctor/${id}`,
            { method: 'GET' }
          );

          if (response.ok) {
            const data = await response.json();
            const citasFiltradas = data.filter(cita => cita.statusCita === 3 || cita.statusCita === 4);
            setCitas(citasFiltradas); 
          } else {
            console.error('Error al obtener las citas:', response.statusText);
          }
        } catch (error) {
          console.error('Error en la solicitud:', error);
        }
      }
    };

    obtenerCitas();
  }, [id]);

  const handleGenerarReceta = (citaSeleccionada) => {
    navigate('/Recetas', { state: { Cita: citaSeleccionada } });
  };

  const handleMostrarPdf = (cita) => {
    return <PdfDoc cita={cita} />;
  };

  const handleSeleccionarCita = (cita) => {
    setCitaSeleccionada(citaSeleccionada && citaSeleccionada.idCita === cita.idCita ? null : cita);
  };

  useEffect(() => {
    const obtenerEspecialidades = async () => {
      try {
        const response = await fetch(
          "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Especialidades/api/List",
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setEspecialidades(data);
        } else {
          console.error('Error al obtener las especialidades:', response.statusText);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };

    obtenerEspecialidades();
  }, []);

  const obtenerNombreEspecialidad = (idEspecialidad) => {
    const especialidad = especialidades.find(e => e.id === idEspecialidad);
    return especialidad ? especialidad.nombre : 'Especialidad no encontrada';
  };

  return (
    <div>
      <h1 className='doctor-title'>Consultas Finalizadas</h1>
      <div className="project-boxes jsGridView">
        {citas.length > 0 ? (
          citas.map((cita, index) => {
            const { fecha, hora } = formFechaHora(cita.fechaHoraCita);
            return (
              <div key={cita.idCita} className="project-box-wrapper">
                <div
                  className={`project-box color-${(index % 6) + 1} ${citaSeleccionada && citaSeleccionada.idCita === cita.idCita ? 'project-box-seleccionada' : ''}`}
                  onClick={() => handleSeleccionarCita(cita)}
                >
                  <div className="project-box-header">
                    <span>{fecha} - {hora}</span>
                  </div>
                  <div className="project-box-content-header">
                    <p className="box-content-header">{cita.tipoCita === 1 ? "Presencial" : "En línea"}</p>
                    <p className="box-content-subheader">{obtenerNombreEspecialidad(cita.especialidadCita)}</p>
                  </div>
                  <div className="box-progress-wrapper">
                    <p className="box-progress-header">Costo</p>
                    <p>{cita.costoCita.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</p>
                  </div>
                  {cita.notaCita && (
                    <div className="box-progress-wrapper">
                      <p className="box-progress-header">Nota de la Cita</p>
                      <p>{cita.notaCita}</p>
                    </div>
                  )}
                  {citaSeleccionada && citaSeleccionada.idCita === cita.idCita && (
                    <div className="project-btn-wrapper">
                      {cita.statusCita === 4 && handleMostrarPdf(cita)}
                      {cita.statusCita === 3 && (
                        <button onClick={() => handleGenerarReceta(cita)} className="btn-finalizar">Generar Receta</button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p>No tiene atenciones médicas completadas.</p>
        )}
      </div>
    </div>
  );
}

export default Finalizadas;